/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const LinkBoxStyle = (bg) => css`
    border-radius: 10px;
    padding: 30px 55px 100px 55px;
    box-sizing: border-box;
    box-shadow: 0 20px 50px 10px rgb(20 61 80 / 15%);
    background-color: rgba(255,255,255,0.9);
    width: 400px;
    height: 275px;
    background-image: url(${bg});
    background-size: 30%;
    background-position: -10% 100%;
    background-repeat: no-repeat;
    border: 1px solid #fff;

    font-size: 45px;
    color: #000;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;

    transition: all 0.2s;
    margin-bottom: 40px;

    &:hover{
        background-color: rgba(255,255,255,0.95);
        box-shadow: 0 20px 30px 10px rgb(20 61 80 / 45%);
        border: 1px solid #000;
        .text{
            text-decoration: underline;
        }
    }

    &:focus-visible{
        outline: 3px solid yellow;
        outline-offset: 4px; 
    }

    .text{
        font-weight: 500;
    }

    span.explanation{
        font-size: 18px;
        max-width: 300px;
        font-weight: 300;
    }

`


