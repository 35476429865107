/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const viewportSmall = 420;
export const viewportMedium = 768;
export const viewportLarge = 1120;

const mediaQueryMaxWidth = (width) => `@media (max-width: ${width}px)`;
const mediaQueryMinWidth = (width) => `@media (min-width: ${width}px)`;

export const small = mediaQueryMaxWidth(viewportSmall);
export const medium = mediaQueryMinWidth(viewportMedium);
export const large = mediaQueryMinWidth(viewportLarge);

export const GlobalStyle = css`

  html, body, 
  button, div, span, applet,
  object, iframe, h1, h2, h3, h4,h5, h6,
  p, blockquote, pre,a,abbr, acronym,address,
  big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,
  small,strike,strong,sub,sup,tt,b,u,i,center,dl,
  dt,dd,ol,ul,li,fieldset,form,label,legend,table,
  caption,tbody,tfoot,thead,tr,th,td,article,aside,
  canvas,details,embed,figure,figcaption,footer,
  header,hgroup,menu,nav,output,ruby,section,summary,
  time,mark,audio,video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    }

  body{
    background: url('/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-family: 'Roboto', sans-serif;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border;
    ${large}{
      overflow:hidden;
    }


  }
  .header {
    background-color: rgba(255,255,255,0.7);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    padding: 10px 0px 5px 20px;
    .logo{
      height: 30px;
    }
  }

`

export const AppStyle = css`

  margin-top: 20%;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  flex-wrap: wrap;
  flex-direction: column;

  ${large}{
    margin-top: 0;
    flex-direction: row;
    min-height: 40vh;
  }


  h1{
    flex-basis: 100%;
    color: #000;
    font-size: 70px;
    text-align: center;
    padding-bottom: 20px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    
    span{
      width: calc(800px + 5%);
      display: block;
      background: rgba(255,255,255, 0.9);
      border-radius: 5px;
      position: relative;


    }
  }

`